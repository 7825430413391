<template>
	<div class="checkout-wrap">
		<emb-page-title
			heading="Checkout"
		>
		</emb-page-title>
		<div class="section-gap">
			<v-container grid-list-xl py-0>
				<template v-if="cart && cart.length > 0">
					<div class="view-cart mb-12">
						<emb-sidebar-panel></emb-sidebar-panel>
					</div>
					<v-layout row wrap mt-4>
						<v-flex lg6 xl6 xs12 md6 sm12>
							<div class="emb-card pa-6">
								<div >
									<h4>Iniciar sesión</h4>
									<v-form>
										<v-text-field	required	type="email" placeholder="Correo electrónico"></v-text-field>
										<v-text-field	required	type="password" placeholder="Contraseña"></v-text-field>
										<div class="d-flex align-center justify-space-between">
											<v-checkbox v-model="checkbox" label="Recordarme"></v-checkbox>
											<router-link to="/session/forgot-password" class="text-lg-right mb-1">¿Olvidaste tu contraseña?</router-link>
										</div>
										<v-btn class="accent mb-4 ml-0 mx-0" large to="/checkout/payment">
											Ingresar
										</v-btn>
										<p>¿No tienes cuenta? <router-link to="/session/signup">Haga clic aquí para crear una</router-link></p>
									</v-form>
								</div>
							</div>
						</v-flex>
						<v-flex lg6 xl6 xs12 md6 sm12>
							<div class="emb-card pa-6">
								<h4>Pago de invitado</h4>
								<p>Proceda al pago y cree una cuenta más tarde</p>
								<v-btn class="accent mt-0 mr-0 mx-0" large to="/checkout/payment">
									Continuar como invitado
								</v-btn>
							</div>
						</v-flex>
					</v-layout>
				</template>
				<template v-else>
					<div class="text-center">
						<div class="mb-6">
							<img  alt="cart-empty" height="128" src="static/images/empty-cart.png" width="128">
						</div>
						<h4 > Tu bolsa de compras está vacía.</h4>
						<router-link class="primary--text" to="/">Ir a la tienda</router-link>
					</div>
				</template>
			</v-container>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	data(){
		return{
			checkbox: false
		}
	},
	computed: {
		...mapGetters(["cart"]),
	},
}
</script>